<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'avoir-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter Avoir</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="avoirs"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            v-if="props.row.status === 'vente direct'"
            variant="light-warning"
          >
            <span>Vente direct</span>
          </b-badge>
          <b-badge
            v-if="props.row.status === 'vente en ligne'"
            variant="light-info"
          >
            <span>Vente en ligne</span>
          </b-badge>

          <b-badge
            v-if="props.row.status === 'vente gros'"
            variant="light-success"
          >
            <span>
              Vente Gros
            </span>
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
              @click="deleteAvoir(props.row)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="
                $router.push({
                  name: 'avoir-show',
                  params: { id: props.row.id },
                })
              "
            />
            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />

            <!-- <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="
                $router.push({
                  name: 'avoir-edit',
                  params: { id: props.row.id },
                })
              "
            />
            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            /> -->
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Numéro',
          field: 'reference',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher numéro',
          },
        },
        {
          label: 'Client',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Client',
          },
        },

        {
          label: 'Date',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher status',
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      avoirs: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAvoirs()
  },
  methods: {
    async getAvoirs() {
      this.load = 'true'
      await axios.get('/avoirs/list/').then(response => {
        this.avoirs = response.data
        this.load = 'false'
      })
    },
    // delete avoir
    deleteAvoir(avoir) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .post('/avoirs/delete/', { avoir })
            .then(() => {
              this.load = 'false'
              this.getAvoirs()
              setTimeout(() => {
                this.showToast(
                  'success',
                  'top-center',
                  'vente supprimée avec succés',
                )
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Devis Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
